<template>
      <div class="iu-footer">
      <el-button type="success" size="mini" @click="$emit('showMore') "
      >完善更多询价信息
      </el-button
      >
      <el-button type="primary" size="mini" @click="$emit('createOrder') "
      >生成询价单
      </el-button
      >
    </div>
</template>

<script>
export default {



}
</script>

<style>
.iu-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;

}

</style>