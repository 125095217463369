<template>
	<el-dialog
	    :width="width"
		:destroy-on-close="true"
		@open="open"
	  >
		<div class="a">
			<div class="a-content">
				<slot></slot>
			</div>
			<div class="a-footer">
				<el-button size="mini" style="margin-right: 20px;" @click="close">{{cancelText}}</el-button>
				<el-button size="mini" style="margin-left: 20px;" type="primary" @click="confirm">{{confirmText}}</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:{
			width:{
				type:String,
				default:'600px'
			},
			cancelText:{
				type:String,
				default:'取消'
			},
			confirmText:{
				type:String,
				default:'确认'
			}
		},
		methods:{
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			confirm(){ //确认的点击事件
				this.$emit('confirm');
			},
			open(){ //打开
				this.$emit('open');
			},
		},
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px 40px;
		.a-title{
			font-weight: bold;
			padding-bottom: 20px;
		}
		.a-item{
			display: flex;
			align-items: center;
			padding-bottom: 10px;
			span{
				width: 88px;
				text-align: right;
				margin-right: 20px;
			}
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
</style>
