<template>
  <div class="p">
    <div class="p-top">
      <el-input v-model="value" :rows="10" type="textarea" placeholder="例如：
	A9F29413  1
	iC65N 2P C16A  2
	LC1DT32M7C  1
	6ES7511-1CK01-0AB0  6
	6ES7521-1BH50-0AA0  3
	6ES7540-1AB00-0AA0  3" style="margin-right: 40px" />
      <!-- @keydown.enter="search" -->
      <upload-file v-model="files" :list="projectList" />
    </div>
    <div style="
        padding: 10px 0 0px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      ">
      <div style="flex-basis: 100%; display: flex; align-items: center">
        <el-button size="mini" @click="search" type="primary">查询</el-button>
        <div style="color: #999999; margin-left: 20px; font-size: 12px">
          批量添加订货号（型号）和数量，用空格隔开，也可从Excel中复制粘贴，然后按查询。
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; margin: 10px 0;align-items: center;">
        <div style="flex-shrink: 0;margin-right: 20px">
          <span>已选择<span style="color: #d84146">{{ data.length || 0 }}</span>种商品，共<span style="color: #d84146">{{
            data.length || 0 }}</span>种</span>
          <span >总价（不含运费）：<span style="color: #d84146">{{
            "￥" + number.toFixed(2)
              }}</span></span>
        </div>
        <el-button type="primary" size="mini" style="cursor: pointer; margin-right: 10px" @click="exportData">
          导出询价单
        </el-button>
      </div>
      <Confirm @createOrder="createOrder" @showMore="showMore"></Confirm>
    </div>
    <!-- 商品信息 -->
    <lida-table :pagination="false" :cutHeight="0" :height="HEIGHT" :data="data" ref="table" id="mytable">
      
      <el-table-column type="index" label="序号" width="100" align="center" />
      <lida-table-column label="产品信息" align="center">
        <template #default="scope">
          <div class="g-info-right" style="display: flex;flex-direction: column;align-items: center;">
            <div class="g-info-row g-info-title" v-if="scope.row.id">
              {{ scope.row.productName }} | {{ scope.row.seriesName }}
            </div>
            <div class="g-info-row" v-if="scope.row.id">
              <span>型号:{{ scope.row.barCode }}</span> 
            </div>
            <div class="g-info-row">
              <span>订货号:{{ scope.row.articleNo }}</span> 
            </div>
            <div class="g-info-row">
              <el-tag v-if="scope.row.id">{{ scope.row.brandName }}</el-tag>
              <el-button v-if="!scope.row.id" size="mini" type="danger" plain
                @click="errorCorrection(scope.row.articleNo, scope.$index)">纠错
              </el-button>
            </div>
          </div>
        </template>
      </lida-table-column>
      <lida-table-column label="系列号" prop="seriesName" width="250" align="center" />
      <lida-table-column label="商品名称" prop="productName" align="center">
        <template #default="scope">
          <span>{{ scope.row.productName }}</span>
          <el-popover v-if="
            scope.row.skuReplaceVOList &&
            scope.row.skuReplaceVOList.length > 0
          " placement="top" width="400" trigger="click">
            <div class="product-list-box">
              <p class="p-title">可替换商品</p>
              <div class="p-list" v-for="(item, index) in scope.row.skuReplaceVOList" :key="index + 'product'">
                <p class="p-l-title">{{ item.productName }}</p>
                <div class="image-box">
                  <img :src="item.filesUrl" alt="" />
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <p class="i-label">生产厂家：</p>
                    <p class="i-value">{{ item.brandName }}</p>
                  </div>
                  <div class="info-item">
                    <p class="i-label">面价：</p>
                    <p class="i-value">{{ item.guidePrice }}</p>
                  </div>
                  <div class="info-item">
                    <p class="i-label">参数：</p>
                    <p class="i-value">{{ item.param }}</p>
                  </div>
                  <div class="info-item">
                    <p class="i-label">订货号：</p>
                    <p class="i-value">{{ item.articleNo }}</p>
                  </div>
                  <div class="info-item">
                    <p class="i-label">型号：</p>
                    <p class="i-value">{{ item.barCode }}</p>
                  </div>
                  <div class="info-item">
                    <p class="i-label">系列：</p>
                    <p class="i-value">{{ item.seriesName }}</p>
                  </div>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="changeProduct(scope)">替换商品
                </el-button>
              </div>
            </div>
            <template #reference>
              <el-button size="mini" type="danger" plain v-if="
                scope.row.skuReplaceVOList &&
                scope.row.skuReplaceVOList.length > 0
              ">替换商品
              </el-button>
            </template>
          </el-popover>
        </template>
      </lida-table-column>
      <lida-table-column label="产品说明" align="center" prop="auxiliaryRemarksOne" width="300" />
      <lida-table-column label="面价" align="center" prop="guidePrice" width="100" />
      <lida-table-column label="数量" align="center" prop="skuQty" width="160">
        <template #default="scope">
          <el-input-number v-model="scope.row.skuQty" :min="0" size="small" />
        </template>
      </lida-table-column>
      <lida-table-column label="操作" fixed="right" align="center">
        <template #default="scope">
          <el-button size="mini" @click="deleteRow(scope)" type="text">移除
          </el-button>
        </template>
      </lida-table-column>
    </lida-table>

    <el-dialog v-model="dialogTableVisible" width="60%">
      <div class="a">
        <div class="a-title">
          替换商品 (您输入的产品<label style="color: red">订货号/型号</label>可能有误，系统为您智能筛选出所输型号相近的产品供您选择。)
        </div>
        <el-row style="margin-bottom: 10px" :gutter="20">
          <el-col :span="6">
            <el-input v-model="wd" placeholder="请输入" @input="getProduct"></el-input>
          </el-col>
          <!--                    <el-col :span="6">-->
          <!--                        <el-button @click="getProduct">查询</el-button>-->
          <!--                    </el-col>-->
        </el-row>
        <lida-table :data="replaceData" border style="width: 100%" :cutHeight="0" :page="pageNo" :pageSize="pageSize"
          :total="total" :pagination="true" ref="table" @change="pageChange">
          <el-table-column property="brandName" align="center" label="品牌"></el-table-column>
          <el-table-column property="productName" align="center" label="商品名称"></el-table-column>
          <el-table-column property="barCode" align="center" label="型号"></el-table-column>
          <el-table-column property="articleNo" align="center" label="订货号"></el-table-column>
          <el-table-column property="guidePrice" align="center" label="面价"></el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <el-button @click="replace(scope.row)">替换</el-button>
            </template>
          </el-table-column>
        </lida-table>
        <div class="a-footer">
          <el-button style="margin-right: 20px" size="mini" @click="dialogTableVisible = false">关闭
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * zhd
 * wdBatch 只有在我的采购-》我的询价 -》添加询价页面 不要复用组件
 */
import uploadFile from "@/components/publicComponent/uploadFile.vue";
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";
import Confirm from "../../viewPage/confirm.vue";
export default {
  data() {
    return {
      value: "", //搜索值
      files: [], //文件files
      ids: [], //禁用的商品ids
      data: [], //列表数据
      searchShow: false,
      searchData: [],
      projectList: [], //文件列表
      dataKey: "1",
      dialogTableVisible: false,
      replaceData: [],
      currentReplaceIndex: "",
      currentReplaceArticleNo: "",
      total: 0,
      pageNo: 1,
      pageSize: 10,
      wd: "",
      number: 0,
      timer: null,
      HEIGHT: 200
    };
  },

  props: {
    goods: { type: Array },
  },
  components: {
    uploadFile,
    Confirm,
  },
  computed: {
    ...mapState("purchaseMudule", ["turnInquiryInfo"]),
  },
  methods: {
    showMore() {
      this.$emit("showMore");
    },
    createOrder() {
      this.$emit("createOrder");
    },
    ...mapActions("purchaseMudule", ["skuPage"]),
    pageChange(page, pageSize) {
      //分页的改变事件
      this.pageNo = page;
      this.pageSize = pageSize;
      this.getProduct();
    },
    //替换
    replace(row) {
      row.skuQty = 1;
      // console.log(row.skuQty);
      this.data[this.currentReplaceIndex] = { ...row };

      this.dialogTableVisible = false;
    },
    search() {
      //搜索的点击事件
      if (this.value) {
        this.skuPage({
          wdBatch: this.value,
          pageNo: 1,
          pageSize: 100,
        }).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            this.number = 0;
            //请求成功
            //每次搜索结果数据累加
            data.rows.map((item) => {
              item.skuQty = Number(item.wdNumber) || 1;
              if (item.skuReplaceVOList && item.skuReplaceVOList.length > 0) {
                for (const val of item.skuReplaceVOList) {
                  val.skuQty = Number(val.wdNumber) || 1;
                  if (val.files) {
                    const files = JSON.parse(val.files);
                    val.filesUrl = files[0] ? files[0].url : "";
                  }
                }
              }
              this.data.push(item);
              // if (this.data.findIndex((v) => v.id === item.id) != -1) {
              //   this.data.splice(
              //     this.data.findIndex((v) => v.id === item.id),
              //     1,
              //     item
              //   );
              // } else {
              //   this.data.push(item);
              // }
              // !this.data.some(v => v.id === item.id) && this.data.push(item);
              this.number += item.guidePrice * item.skuQty;
              
              this.HEIGHT = 45 + this.data.length * 115
            });
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message: "请输入查询条件",
        });
      }
      //阻止浏览器默认事件
      let e = window.event || arguments[0];
      if (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13) {
        e.returnValue = false;
        return false;
      }
    },
    //下载表格
    exportData() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../mixins/ExportExcel"); //路径要对
        const Header = [
          "序号",
          "品牌",
          "商品名称",
          "型号",
          "订货号",
          "面价",
          "数量",
        ];
        const filterVal = [
          "index",
          "brandName",
          "productName",
          "barCode",
          "articleNo",
          "guidePrice",
          "skuQty",
        ];
        const lists = this.data.map((v, i) => {
          return {
            index: i + 1,
            brandName: v.brandName,
            productName: v.productName,
            barCode: v.barCode,
            articleNo: v.articleNo,
            guidePrice: v.guidePrice,
            skuQty: v.skuQty,
          };
        });
        const data = this.formatJson(filterVal, lists);
        export_json_to_excel(Header, data, "询价单");
      });
    },

    formatJson(filterVal, lists) {
      return lists.map((item) => filterVal.map((item1) => item[item1]));
    },
    getProduct() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.replaceData = [];
      if (!this.wd) {
        return;
      }
      this.timer = setTimeout(async () => {
        let result = await this.skuPage({
          wd: this.currentReplaceArticleNo,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          wd: this.wd,
        });
        this.replaceData = result.data.data.rows;
        this.total = result.data.data.totalCount;
        this.pageSize = result.data.data.pageSize;
        this.pageNo = result.data.data.pageNo;
      }, 200);
    },
    //纠错
    async errorCorrection(articleNo, index) {
      this.dialogTableVisible = true;
      this.currentReplaceArticleNo = articleNo;
      this.currentReplaceIndex = index;
      this.wd = articleNo;
      await this.getProduct();
    },
    deleteRow(obj) {
      //删除商品的事件
      this.data.splice(obj.$index, 1);
      
      this.HEIGHT = 45 + this.data.length * 115
    },
    addGoods(data) {
      //添加商品
      this.data = [...this.data, ...data];
    },
    changeProduct(scope) {
      this.data.splice(scope.$index, 1, ...scope.row.skuReplaceVOList);
      this.dataKey = Math.random();
    },
  },
  created() {
    this.data = this.goods || [];
    var info = JSON.parse(JSON.stringify(this.turnInquiryInfo));
    if (info) {
      info = info.inquiryRequestVO;
      //回显信息
      var projectFile =
        typeof info.file === "string"
          ? JSON.parse(info.file)
          : info.file
            ? info.file
            : [],
        projectList = []; //回显的文件列表
      projectFile.map((item) => {
        projectList.push({
          name: item.fileName,
          url: item.url,
          fileId: item.fileId,
        });
      });
      this.projectList = [...projectList];
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (v) {
        var ids = [];
        v.map((item) => {
          ids.push(item.id);
        });
        this.ids = ids;
        this.$emit("changeinfo", v, this.files, this.value);
      },
    },
    files(v) {
      this.$emit("changeinfo", this.data, v, this.value);
    },
  },
};
</script>

<style scoped lang="less">
.a {
  padding: 20px;
  // height: 110%;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}

.p {
  .p-top {
    display: flex;
    align-items: center;
  }
}

.product-list-box {
  .p-title {
    padding: 10px 15px;
    box-sizing: border-box;
  }

  .p-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .p-l-title {
      width: 100%;
      color: #d84146;
      font-weight: 700;
      padding: 10px 15px;
      box-sizing: border-box;
    }

    .image-box {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      border-radius: 5px;
    }

    .info-list {
      width: calc(100% - 137px);
      margin-left: 15px;

      .info-item {
        display: flex;
        margin: 5px 0;
      }

      .i-label {
        color: #868686;
        width: 90px;
      }

      .i-value {
        color: #000;
        flex: 1;
      }
    }
  }

  .btn-box {
    text-align: center;
  }
}

// .lida-main{
//   height: 110%!important
// }</style>
