<template>
  <div class="a">
    <div style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      ">
      <div style="flex: 11">
        <inquiryMainForm ref="mainForm" @isPlatform="isPlatform"></inquiryMainForm>
      </div>
      <div style="
          width: 360px;
          margin-left: 40px;
          display: flex;
          justify-content: flex-end;
        ">
        <ContactWechat></ContactWechat>
      </div>
    </div>
    <div style="margin-bottom: 0px" v-if="userInfo.userType === '0'">
      <el-form style="width: 100%" label-width="100px" ref="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="询价人" prop="inquiryCustomerId">
              <div class="flex">
                <el-select label="询价人" v-model="value" filterable placeholder="选择客户询价人" @change="getinquiryCustomerId">
                  <el-option :label="item.realName +
                    '/' +
                    item.enterpriseName +
                    '/' +
                    item.bindPhone
                    " :value="item.id" :key="item.id" v-for="item in customerReals"></el-option>
                </el-select>
                <div style="width: 20px"></div>
                <el-select label="询价人" v-model="valueSupplier" filterable placeholder="选择供应商询价人"
                  @change="getsupplierRealsId">
                  <el-option :label="item.realName +
                    '/' +
                    item.enterpriseName +
                    '/' +
                    item.bindPhone
                    " :value="item.id" :key="item.id" v-for="item in supplierReals"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="备注">
              <el-input :rows="2" type="textarea" v-model="remarks" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <product-info @showMore="showMore" @createOrder="createOrder" @changeinfo="productChange" :goods="goods" />
    <lidaDialog v-model="showMainForm" width="1000px" confirmText="生成询价单" @confirm="createOrder">
      <inquiry-form :goods="goods" :files="files" :cities="cities" :info="obj" :formDefaultValues="{ value , valueSupplier , remarks }"
        ref="form" @success="addSuccess" @change="costPlannedAmountChange($event)"
        @changeRemarks="changeRemarks($event)" />
    </lidaDialog>
  </div>
  <share-link-dialog v-model="shareLinkShow" :copyContent="searchValue" :inquiryId="inquiryId" @goClose="close" />
</template>

<script>
import lidaDialog from "@/components/publicComponent/dialog.vue";
import inquiryForm from "../components/inquiryForm.vue"; //表单信息
import inquiryMainForm from "../components/inquiryMainForm.vue"; //主表单信息
import productInfo from "../components/addInquiryPage/productInfo.vue"; //产品信息
import { mapState, mapMutations, mapActions } from "vuex";
import shareLinkDialog from "../components/dialog/shareLink.vue";
import ContactWechat from "@/components/publicComponent/ContactWechat";

import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      goods: [], //商品列表
      files: [], //文件files列表
      info: null, //需要回显的信息
      inquiryId: null, //生成成功的询价单id
      shareLinkShow: false, //分享弹框的隐藏显示
      showMainForm: false, //更多询价信息隐藏显示
      obj: {}, //
      supplierReals: [], //询价人列表
      customerReals: [], //询价人列表
      remarks: "", //备注
      cities: [],
      platForm: "1",
      value: "",
      valueSupplier: "",
      searchValue: "" /*搜索条件*/,
    };
  },
  computed: {
    ...mapState("purchaseMudule", [
      "paymentTypes",
      "protects",
      "moneys",
      "projectType",
      "projectState",
      "turnInquiryInfo",
    ]),
    userInfo() {
      //当前用户基本信息
      return this.$store.state.userInfo;
    },
  },
  components: {
    ContactWechat,
    inquiryForm,
    productInfo,
    shareLinkDialog,
    inquiryMainForm,
    lidaDialog,
  },
  created() {
    var info = JSON.parse(JSON.stringify(this.turnInquiryInfo));
    if (info) {
      var goods = [];
      info.itemRequestVOList.map((item) => {
        item.id = item.skuId || item.id;
        goods.push(item);
      });
      this.goods = [...goods];
    }
  },

  mounted() {
    if (this.userInfo.userType === "0") {
      //如果是供应商就查询询价人列表
      this.getUserList();
    }
  },
  methods: {
    ...mapMutations("purchaseMudule", ["renewTurnInquiryInfo"]),
    ...mapActions("purchaseMudule", [
      "userListByShareEnterpriseId",
      "toAutoQuoteByInquiryId",
    ]),
    changeRemarks(v) {
      this.remarks = v;
    },
    getUserList() {
      //获取询价客户列表
      this.userListByShareEnterpriseId({}).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          this.supplierReals = data.filter((data) => {
            return data.userType === "0";
          });
          this.customerReals = data.filter((data) => {
            return data.userType === "1";
          });
        }
      });
    },
    getinquiryCustomerId(val) {
      this.value = val;
      this.valueSupplier = "";
    },
    getsupplierRealsId(val) {
      this.valueSupplier = val;
      this.value = "";
    },
    // eslint-disable-next-line vue/no-dupe-keys
    isPlatform(f) {
      this.platForm = f;
    },
    // 事件处理函数
    async costPlannedAmountChange(value) {
      this.value = value;
    },
    createOrder() {
      //生成询价单
  
      this.showMainForm = true;
      this.$refs["mainForm"].confirm((info) => {
        var goods = this.goods;
        if (this.goods.length) {
          this.cities = this.$refs["mainForm"].cities;
          this.$nextTick(() => {
            this.$refs["form"].createOrder(info, (id) => {
              this.toAutoQuoteByInquiryId({ inquiryId: id }).then((res) => {
                console.log(res);
              });
              this.addSuccess(id);
            });
          });
        } else {
          ElMessage({
            type: "warning",
            message: "请添加询价商品",
          });
        }
      });
    },
    productChange(goods, files, value) {
      goods = JSON.parse(JSON.stringify(goods || []));
      files = JSON.parse(JSON.stringify(files || []));
      this.goods = goods;
      this.files = files;
      this.searchValue = value;
    },
    addSuccess(id) {
      //添加成功返回上一页
      this.inquiryId = id;
      this.showMainForm = false;
      this.shareLinkShow = true;
      this.obj = {};
      this.$refs["mainForm"].clearData();
    },
    close() {
      //分享弹框关闭后返回上一页
      this.$router.push("/purchase/enquiry");
    },
    showMore() {
      this.$refs["mainForm"].confirm((info) => {
        this.obj = info;
        this.cities = this.$refs["mainForm"].cities;
        this.showMainForm = true;
      });
    },
  },

  watch: {
    $route(v) {
      this.renewTurnInquiryInfo(null); //清空值
    },
  },
};
</script>

<style scoped lang="less">
.a {
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  overflow: hidden;

  .a-content {
    flex: 1;
    overflow: hidden;
  }
}

.a-form {
  display: flex;
  flex-direction: column;
}
</style>
